import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {HeaderHeight, HeaderWidth, MaxNameWidth, LeftMargin} from '../const/sizes';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit, OnChanges {
  @Input() userName;
  @Input() title;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  constructor() { }

  ngOnInit() {
    this.setContext();
    setTimeout(() => {
      this.changeText();
    });
  }

  ngOnChanges() {
    this.changeText();
  }

  setContext() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
  }

  changeText() {
    if (!this.ctx) {
      return;
    }
    this.ctx.save();
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.setText();
    this.ctx.restore();
  }

  setText() {
    this.setName();
    this.setTitle();
  }

  setName() {
    this.ctx.font = '50px Raleway';
    this.ctx.fillStyle = 'white';
    this.ctx.fillText(this.userName, LeftMargin, this.canvas.nativeElement.height / 2 + 15, MaxNameWidth);
  }

  setTitle() {
    this.ctx.font = '200 22px Raleway';
    this.ctx.fillStyle = 'white';
    this.ctx.fillText(this.title, LeftMargin, this.canvas.nativeElement.height / 2 + 50, MaxNameWidth);
  }

  downloadClicked() {
    this.setBackground();
  }

  downloadCanvas(fileName = 'header-image') {
    const canvasDataUrl = this.canvas.nativeElement.toDataURL();
    const link = document.createElement('a'); // create an anchor tag

    // set parameters for downloading
    link.setAttribute('href', canvasDataUrl);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', fileName);

    // compat mode for dispatching click on your anchor
    if (document.createEvent) {
      const evtObj = document.createEvent('MouseEvents');
      evtObj.initEvent('click', true, true);
      link.dispatchEvent(evtObj);
    } else if (link.click) {
      link.click();
    }
  }

  setBackground() {
    const background = new Image();
    background.src = 'assets/images/background.png';
    background.onload = () => {
      this.ctx.drawImage(background, 0, 0, HeaderWidth, HeaderHeight);
      this.setText();
      this.downloadCanvas();
    };
  }

}
